import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Nav from '../components/Nav/Nav'
import Youtube from '../components/Youtube/Youtube'

export default () => (
  <Layout>
    <SEO title="Videos" keywords={[`gatsby`, `application`, `react`]} />
    <Nav />

    <div>
      <div>
        <Youtube video="qdtf182YJ5o" autoplay="0" rel="0" modest="1" />
      </div>
      <div>
        <Youtube video="7nfty6SshKY" autoplay="0" rel="0" modest="1" />
      </div>
    </div>

  </Layout>
)
