import React from 'react'

export default props => {
  const videoSrc =
    'https://www.youtube.com/embed/' +
    props.video +
    '?autoplay=' +
    props.autoplay +
    '&rel=' +
    props.rel +
    '&modestbranding=' +
    props.modest
  return (
    <div className="container">
      <iframe
        title="youtube"
        className="player"
        type="text/html"
        src={videoSrc}
        frameborder="0"
      />
    </div>
  )
}
